<template>
	<v-row>
		<v-snackbar v-model="snackbar.display" :timeout="3000" :color="snackbar.color">
			{{ snackbar.message }}
		</v-snackbar>
		<v-col md="2" cols="0"></v-col>
		<v-col text-align="center" justify="center" md="8" cols="12">
			<v-card class="card" align="center" justify="center" tile flat>
				<h1>{{ $t("Form.Title") }}</h1>

				<v-form class="pl-4" ref="form" v-model="valid">
					<v-text-field class="text-form" filled dark v-model="name" :rules="nameRules" :label="$t('Form.Name')" required></v-text-field>

					<v-text-field class="text-form" filled dark v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>

					<v-text-field class="text-form" filled dark v-model="object" :rules="objectRules" :label="$t('Form.Object')" required></v-text-field>

					<v-textarea class="text-form" filled dark v-model="message" :rules="messageRules" label="Message" required></v-textarea>
					<v-btn :disabled="!valid" dark @click="sendForm">{{ $t("Form.Button") }}</v-btn>
				</v-form>
			</v-card>
		</v-col>
		<v-col md="2" cols="0"></v-col>
	</v-row>
</template>

<script>
import axios from "axios";

export default {
	data() {
		return {
			snackbar: {
				display: false,
				color: "",
				message: "",
			},
			valid: true,
			name: "",
			nameRules: [(v) => !!v || this.$t("Form.NameReq")],
			email: "",
			emailRules: [(v) => !!v || this.$t("Form.EmailReq"), (v) => /.+@.+\..+/.test(v) || this.$t("Form.EmailErr")],
			object: "",
			objectRules: [(v) => !!v || this.$t("Form.ObjectReq"), (v) => (v && v.length <= 20) || this.$t("Form.ObjectErr")],
			message: "",
			messageRules: [(v) => !!v || this.$t("Form.MessageReq"), (v) => (v && v.length >= 10) || this.$t("Form.MessageErr")],
			checkbox: false,
		};
	},
	methods: {
		async sendForm() {
			var data = {
				name: this.name,
				email: this.email,
				object: this.object,
				message: this.message,
			};

			var config = {
				method: "post",
				url: "https://api.web3society.io/form",
				headers: {
					"Content-Type": "application/json",
				},
				data: data,
			};
			try {
				const response = await axios(config);
				console.log(response.status);
				this.displaySnackbar("green", this.$t("Form.Confirm"));
				this.name = "";
				this.email = "";
				this.object = "";
				this.message = "";
			} catch (error) {
				this.displaySnackbar("green", this.$t("Form.Error"));
			}
		},

		displaySnackbar(color, message) {
			this.snackbar.display = true;
			this.snackbar.color = color;
			this.snackbar.message = message;
			console.log(this.snackbar);
		},
	},
};
</script>

<style scoped>
.snackbar {
	padding: 5px;
}

.text-form {
	padding-left: 20% !important;
	padding-right: 20% !important;
}
h1 {
	color: rgba(255, 255, 255, 0.788);
	font-size: 24px;
	margin-bottom: 50px;
}
.card {
	margin-top: 50px;
	color: white;
	margin-bottom: 10px;
	background-color: transparent !important;
}
@media (max-width: 600px) {
	.text-form {
		padding-left: 40px !important;
		padding-right: 40px !important;
	}
}
</style>
