<template>
	<div class="particles">
		<div id="particles-js"></div>
		<main>
			<Language />
			<SubHeader />
			<Hero />
			<Key />
			<Cards />
			<Form />
		</main>
	</div>
</template>

<script>
import "particles.js";
import Hero from "./components/Hero";
import Language from "./components/Language";
import Form from "./components/Form";
import Cards from "./components/Cards";
import Key from "./components/Key";
import SubHeader from "./components/SubHeader";

export default {
	name: "app",
	components: {
		Cards,
		Key,
		Form,
		Language,
		Hero,
		SubHeader,
	},
	mounted() {
		this.initParticles();
	},
	methods: {
		initParticles() {
			window.particlesJS("particles-js", {
				particles: {
					number: {
						value: 100,
						density: {
							enable: true,
							value_area: 700,
						},
					},
					color: {
						value: "#ffffff",
					},
					shape: {
						type: "circle",
						stroke: {
							width: 0,
							color: "#000000",
						},
						polygon: {
							nb_sides: 5,
						},
					},
					opacity: {
						value: 0.5,
						random: false,
						anim: {
							enable: false,
							speed: 1,
							opacity_min: 0.1,
							sync: false,
						},
					},
					size: {
						value: 3,
						random: true,
						anim: {
							enable: false,
							speed: 40,
							size_min: 0.1,
							sync: false,
						},
					},
					line_linked: {
						enable: true,
						distance: 150,
						color: "#ffffff",
						opacity: 0.4,
						width: 1,
					},
					move: {
						enable: true,
						speed: 1,
						direction: "none",
						random: false,
						straight: false,
						out_mode: "out",
						bounce: true,
						attract: {
							enable: false,
							rotateX: 600,
							rotateY: 1200,
						},
					},
				},
				interactivity: {
					detect_on: "canvas",
					events: {
						onhover: {
							enable: true,
							mode: "grab",
						},
						onclick: {
							enable: true,
							mode: "push",
						},
						resize: true,
					},
					modes: {
						grab: {
							distance: 140,
							line_linked: {
								opacity: 1,
							},
						},
						bubble: {
							distance: 400,
							size: 40,
							duration: 2,
							opacity: 8,
							speed: 3,
						},
						repulse: {
							distance: 200,
							duration: 0.4,
						},
						push: {
							particles_nb: 4,
						},
						remove: {
							particles_nb: 2,
						},
					},
				},
				retina_detect: true,
			});
		},
	},
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Graduate&display=swap");
body {
	overflow: hidden;
	margin: 0;
	font-family: "Graduate", cursive !important;
	background-color: rgb(12, 12, 12) !important;
}
#particles-js canvas {
	display: block;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	opacity: 1;
	-webkit-transition: opacity 0.8s ease, -webkit-transform 1.4s ease;
	transition: opacity 0.8s ease, transform 1.4s ease;
}
.hero-img {
	padding-top: 50px;
}
#particles-js {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: -10;
	top: 0;
	left: 0;
	background-color: rgb(12, 12, 12);
}
</style>
