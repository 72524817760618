<template>
	<v-system-bar lights-out dark height="30">
		<v-spacer></v-spacer>
		<button class="flag-btn" @click="setLocale('fr')"><flag iso="fr" v-bind:squared="false" /></button>
		<button @click="setLocale('en')"><flag iso="gb" v-bind:squared="false" /></button>
	</v-system-bar>
</template>

<script>
export default {
	methods: {
		setLocale(locale) {
			this.$i18n.locale = locale;
		},
	},
};
</script>

<style scoped>
.flag-btn {
	padding-right: 10px;
}
.theme--light.v-application {
	color: transparent !important;
	height: 30px !important;
}
</style>
